import {
  INFECTION_AND_GERMS_REQUIRES_CLOSTRIDIEN,
  INFECTION_AND_GERMS_REQUIRES_FOUR_MRGN,
  INFECTION_AND_GERMS_REQUIRES_ISOLATION,
  INFECTION_AND_GERMS_REQUIRES_MRSA,
  INFECTION_AND_GERMS_REQUIRES_THREE_MRGN,
  INFECTION_AND_GERMS_REQUIRES_VRE,
  NO_CAPACITY_FOR_CARELEVEL,
  NO_CAPACITY_FOR_GENDER,
  NO_CAPACITY_FOR_INFECTION,
  NO_CAPACITY_FOR_ROOM,
  NO_CAPACITY_FOR_SERVICE,
  NO_CAPACITY_FOR_SOLUTION,
  NO_CAPACITY_FOR_SPECIALIZATION,
  ROOM_TYPE_NO_PREFERENCE,
  SEARCH_TYPE_TRANSPORT,
  SOLUTION_REHAB_FULL_HOSPITALIZATION,
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
} from "core/consts";
import { descriptiveWhitelist } from "core/model/auctions";
import { Auction, AuctionRequest, Careseeker } from "core/types";
import { ageServices } from "dsl/ecosystems/CareproviderSettingsServices/ServicesCheckboxGroup";
import { carelevelWhitelist } from "dsl/organisms/Infos/PatientInformation/modelDefinition";

// TODO: to remove when infections are ontologies
export function convertInfectionsToOntologiesIds(
  infectionKeys: Array<string>,
): Array<number> {
  return infectionKeys.map((infectionKey) => {
    switch (infectionKey) {
      case "requires_isolation":
        return INFECTION_AND_GERMS_REQUIRES_ISOLATION;
      case "mrsa":
        return INFECTION_AND_GERMS_REQUIRES_MRSA;
      case "three_mrgn":
        return INFECTION_AND_GERMS_REQUIRES_THREE_MRGN;
      case "four_mrgn":
        return INFECTION_AND_GERMS_REQUIRES_FOUR_MRGN;
      case "clostridien":
        return INFECTION_AND_GERMS_REQUIRES_CLOSTRIDIEN;
      case "vre":
        return INFECTION_AND_GERMS_REQUIRES_VRE;
      default:
        return -1;
    }
  });
}

export const REASONS_VALUES = [
  {
    reason: NO_CAPACITY_FOR_SOLUTION,
    predicate: ({ solutions }: AuctionRequest) => !!solutions?.length,
    values: ({ solutions }: AuctionRequest) =>
      solutions?.length ? solutions : null,
  },
  {
    reason: NO_CAPACITY_FOR_SERVICE,
    predicate: ({ auction }: AuctionRequest) => auction?.services,
    values: ({ auction }: AuctionRequest) =>
      auction?.services?.filter((service) => !ageServices.includes(service)),
  },
  {
    reason: NO_CAPACITY_FOR_GENDER,
    predicate: ({ auction }: AuctionRequest) => {
      if (!auction?.solutions || auction.search_type === SEARCH_TYPE_TRANSPORT)
        return false;

      const solutions = auction?.solutions;
      const whiteList = [
        SOLUTION_STATIC_CARE,
        SOLUTION_SHORT_TERM_STATIC_CARE,
        SOLUTION_REHAB_FULL_HOSPITALIZATION,
      ];
      return (
        auction?.patient?.profile?.gender &&
        solutions.some((solution) => whiteList.includes(solution))
      );
    },
    values: ({ auction }: AuctionRequest) => [
      auction?.patient?.profile?.gender,
    ],
  },
  {
    reason: NO_CAPACITY_FOR_CARELEVEL,
    predicate: ({ auction }: AuctionRequest, careseeker: Careseeker) =>
      descriptiveWhitelist(carelevelWhitelist)({
        formInputValue: auction as Auction,
        careseeker,
      }),
    values: ({ auction }: AuctionRequest) => {
      const carelevel =
        auction?.patient?.profile?.financing?.carelevel?.level || -1;
      return carelevel > 0 ? [carelevel] : [0];
    },
  },
  {
    reason: NO_CAPACITY_FOR_ROOM,
    predicate: ({ auction }: AuctionRequest) => {
      if (
        !auction?.solutions ||
        auction?.patient?.profile?.preferences?.room_type ===
          ROOM_TYPE_NO_PREFERENCE
      )
        return false;

      return auction?.patient?.profile?.preferences?.room_type;
    },
    values: ({ auction }: AuctionRequest) => [
      auction?.patient?.profile?.preferences?.room_type,
    ],
  },
  {
    reason: NO_CAPACITY_FOR_INFECTION,
    predicate: ({ auction }: AuctionRequest) =>
      auction?.patient?.diagnosis?.infection_and_germs,
    values: ({ auction }: AuctionRequest) => {
      const infections: { [key: string]: ToType } | undefined =
        auction?.patient?.diagnosis?.infection_and_germs;
      if (infections != null) {
        const infectionKeys = Object.keys(infections);
        const trueInfections = infectionKeys.filter(
          (key) => infections[key] === true,
        );
        const infectionsIds = convertInfectionsToOntologiesIds(trueInfections);
        return infectionsIds;
      }
      return [];
    },
  },
  {
    reason: NO_CAPACITY_FOR_SPECIALIZATION,
    predicate: ({ auction }: AuctionRequest) =>
      auction?.specializations != null,
    values: ({ auction }: AuctionRequest) =>
      auction?.specializations != null ? auction.specializations : null,
  },
];
